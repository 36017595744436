import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import { graphql } from 'gatsby';

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      html
    }
  }
`;

const Blog = ({ data }) => {
  return (
    <Layout>
      <BlogTitle>{data.markdownRemark.frontmatter.title}</BlogTitle>
      <BlogDate>{data.markdownRemark.frontmatter.date}</BlogDate>
      <BlogContent
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      ></BlogContent>
    </Layout>
  );
};

const BlogTitle = styled.h3`
  color: ${props => props.theme.titleColor};
  margin-bottom: 0.5rem;
`;

const BlogDate = styled.p`
  color: ${props => props.theme.textColor};
`;

const BlogContent = styled.div`
  color: ${props => props.theme.textColor};
`;

export default Blog;
